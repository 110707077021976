.shabbyShastaContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.textSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 13%;
}

.rightTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  line-height: 2.5rem;
  letter-spacing: 5px;
  padding-top: 5vh;
  padding-bottom: 3vh;
}

.rightText {
  display: flex;
  flex-direction: column;
  font-family: "librebaskerville";
  color: grey;
  line-height: 3rem;
  letter-spacing: 3px;
  text-align: center;
  align-items: center;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  height: 800px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 399px) {
  .shabbyShastaContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .image {
    height: 250px;
  }

  .rightTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .rightTitle {
    font-size: 16px;
  }

  .rightText {
    font-size: 14px;
    width: 80%;
  }
}

@media (min-width: 300px) and (max-width: 599px) {
  .shabbyShastaContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .rightText {
    font-size: 14px;
    width: 80%;
  }

  .rightTitle {
    font-size: 22px;
  }

  .image {
    height: 250px;
    object-position: 50% 100%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .shabbyShastaContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .rightText {
    font-size: 16px;
    width: 80%;
  }

  .rightTitle {
    font-size: 28px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .shabbyShastaContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 28px;
  }

  .rightText {
    font-size: 16px;
    width: 80%;
  }
}

@media (min-width: 1201px) and (max-width: 1800px) {
  .shabbyShastaContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 1.5rem;
  }

  .rightText {
    font-size: 18px;
    width: 60%;
  }
}

@media (min-width: 1801px) and (max-width: 2100px) {
  .shabbyShastaContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 30px;
  }

  .rightText {
    font-size: 18px;
    width: 60%;
  }
}

@media (min-width: 2101px) {
  .shabbyShastaContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 45px;
  }

  .rightText {
    font-size: 20px;
    width: 60%;
  }
}
