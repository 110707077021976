.sectionText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "librebaskerville";
  color: grey;
  cursor: pointer;
  text-align: center;
  line-break: normal;
  width: auto;
  line-height: 3rem;
  letter-spacing: 3px;
  font-family: "AmalfiCoast";
  font-size: 28px;
}

.subTitleText {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 1rem;
  letter-spacing: 5px;
  font-size: 1.2rem;
  word-spacing: 6px;
}

@media (min-width: 300px) and (max-width: 900px) {
  .sectionText {
    font-size: 14px;
  }

  .subTitleText {
    font-size: 14px;
  }
}
