.container {
  display: grid;
  grid-template-columns: 1fr; /* Initially, each image takes full width */
  gap: 0; /* Adjust the gap between images */
}

.leftImageContainer,
.rightImageContainer {
  width: 100%;
  height: auto;
}

.image {
  width: 100%; /* Initial width, takes full width on smaller screens */
  height: 700px;
  object-fit: cover;
}

@media (max-width: 450px) {
  .image {
    height: 250px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .image {
    height: 250px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* On larger screens, two columns with equal width */
  }
}
