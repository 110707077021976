.lastHomepageSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  line-height: 2.5rem;
  letter-spacing: 5px;
  padding-top: 10%;
  padding-bottom: 5%;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "AmalfiCoast";
  color: grey;
  cursor: pointer;
  text-align: center;
  line-break: normal;
  width: auto;
  line-height: 4.5rem;
  letter-spacing: 3px;
  word-spacing: 5px;
  width: 80%;
  padding-bottom: 10%;
}

.image {
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: 50% 80%;
}

@media (max-width: 399px) {
  .text {
    font-size: clamp(16px, 4vw, 18px);
  }

  .title {
    font-size: clamp(24px, 4vw, 26px);
  }

  .image {
    height: 250px;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .text {
    font-size: 18px;
  }

  .title {
    font-size: 26px;
  }

  .image {
    height: 250px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .text {
    font-size: 20px;
  }

  .title {
    font-size: 40px;
    padding-bottom: 7%;
    padding-top: 7vh;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .text {
    font-size: 26px;
  }

  .title {
    font-size: 45px;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .title {
    font-size: 45px;
  }

  .text {
    font-size: 26px;
  }
}

@media (min-width: 1441px) and (max-width: 1920px) {
  .title {
    font-size: 45px;
  }

  .text {
    font-size: 28px;
  }
}

@media (min-width: 1921px) {
  .title {
    font-size: 45px;
  }

  .text {
    font-size: 30px;
  }
}
