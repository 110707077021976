.app {
  background-color: white;
}

.sectionWithImage {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  align-items: center;
}

.imageWrapper,
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.textWrapper {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  align-items: center;
  scroll-margin-top: 120px;
}

.topSectionTitleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.topSectionTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  word-spacing: 15px;
  letter-spacing: 2px;
  margin-bottom: -3rem;
}

.lowerSectionTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  line-height: 2.5rem;
  letter-spacing: 5px;
  padding-top: 5vh;
  margin-bottom: -10%;
}

.sevenFortyTwoTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  margin-top: 10%;
  margin-bottom: -5rem;
  letter-spacing: 5px;
  font-size: 28px;
  word-spacing: 6px;
}

.sevenFortyTwoTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  /* margin-top: 10%; */
  margin-bottom: -5rem;
  letter-spacing: 5px;
  font-size: 28px;
  word-spacing: 6px;
}

.sevenFortyTwoTitleSmallerText {
  font-size: 12px;
  vertical-align: top;
  padding-right: 1vh;
  margin-left: -0 0.5vw;
}

.rvTentTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  letter-spacing: 5px;
  margin-top: 10%;
}

.sectionText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "librebaskerville";
  color: grey;
  cursor: pointer;
  text-align: center;
  line-break: normal;
  width: auto;
  line-height: 3rem;
  letter-spacing: 3px;
}

.sectionTextSpecial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "librebaskerville";
  color: grey;
  cursor: pointer;
  text-align: center;
  line-break: normal;
  width: auto;
  line-height: 3rem;
  letter-spacing: 3px;
}

.sectionTextWhite {
  display: flex;
  margin: 10% 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "librebaskerville";
  color: white;
  cursor: pointer;
  text-align: center;
  line-break: normal;
  width: 60%;
  line-height: 3rem;
  letter-spacing: 3px;
}

.noScroll {
  overflow: hidden;
  height: 100vh;
}

@media (max-width: 399px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 10%;
  }

  .topSectionTitle {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15%;
  }

  .lowerSectionTitle {
    font-size: 16px;
  }

  .sectionSubTitle {
    font-size: 12px;
    font-weight: 600;
    width: 90%;
  }

  .sectionText {
    font-size: 14px;
    width: 78%;
    /* padding-top: 10%; */
    /* padding-bottom: 5%; */
  }

  .sectionTextSpecial {
    font-size: 14px;
    width: 78%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .rvTentTitle {
    font-size: 16px;
    margin-bottom: -2rem;
  }

  .sevenFortyTwoTitle {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .section {
    height: 100%;
    /* scroll-margin-top: 80px; */
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10%;
  }

  .lowerSectionTitle {
    font-size: 22px;
  }

  .sectionSubTitle {
    font-size: 12px;
    font-weight: 600;
    width: 90%;
  }

  .sectionText {
    font-size: 14px;
    width: 80%;
    padding-top: 10%;
    /* padding-bottom: 10%; */
  }

  .sectionTextSpecial {
    font-size: 14px;
    width: 80%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .rvTentTitle {
    font-size: 22px;
    margin-bottom: -2rem;
  }

  .sevenFortyTwoTitle {
    font-size: 22px;
    margin-bottom: -2rem;
  }

  .section {
    height: 100%;
    scroll-margin-top: 80px;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15%;
  }

  .lowerSectionTitle {
    font-size: 28px;
  }

  .sectionSubTitle {
    font-size: 20px;
    font-weight: 600;
    padding-top: 1vh;
  }

  .sectionText {
    font-size: 16px;
    width: 70%;
    /* padding-top: 10%; */
    /* margin-bottom: 15%; */
  }

  .sectionTextSpecial {
    font-size: 16px;
    width: 70%;
    padding-top: 10%;
    margin-bottom: 15%;
  }

  .rvTentTitle {
    font-size: 28px;
    margin-bottom: -4rem;
    padding-top: 5%;
  }

  .sevenFortyTwoTitle {
    font-size: 28px;
    margin-bottom: -1rem;
    /* padding-top: 5%; */
  }

  .section {
    height: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 30px;
    font-weight: 600;
    margin-top: 10%;
  }

  .lowerSectionTitle {
    font-size: 25px;
  }

  .sectionSubTitle {
    font-size: 20px;
    font-weight: 600;
    padding-top: 1vh;
  }

  .sectionText {
    font-size: 16px;
    width: 70%;
    /* padding-top: 10%; */
    /* margin-bottom: 10%; */
  }

  .sectionTextSpecial {
    font-size: 16px;
    width: 70%;
    padding-top: 10%;
    margin-bottom: 10%;
  }

  .rvTentTitle {
    font-size: 28px;
    margin-bottom: -6rem;
  }

  .section {
    height: 100%;
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 38px;
    font-weight: 600;
    margin-top: 10%;
  }

  .lowerSectionTitle {
    font-size: 30px;
  }

  .sectionText {
    font-size: 18px;
    width: 60%;
    padding-top: 5%;
  }

  .sectionTextSpecial {
    font-size: 18px;
    width: 60%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .section {
    height: 100%;
  }
}

@media (min-width: 1501px) and (max-width: 1800px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 15%;
  }

  .lowerSectionTitle {
    font-size: 1.5rem;
  }

  .sectionText {
    font-size: 18px;
    width: 60%;
    /* padding-top: 3%; */
  }

  .sectionTextSpecial {
    font-size: 18px;
    width: 60%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .rvTentTitle {
    font-size: 1.5rem;
    margin-bottom: -3rem;
    margin-top: 4rem;
  }

  .sevenFortyTwoTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .section {
    height: 100%;
    /* margin-bottom: 100px; */
  }
}

@media (min-width: 1801px) and (max-width: 2100px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 38px;
    font-weight: 600;
    margin-top: 15%;
  }

  .lowerSectionTitle {
    font-size: 30px;
  }

  .sectionText {
    font-size: 20px;
    width: 60%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .sectionTextSpecial {
    font-size: 20px;
    width: 60%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .rvTentTitle {
    font-size: 28px;
    margin-bottom: -3rem;
    margin-top: 4rem;
  }

  .sevenFortyTwoTitle {
    font-size: 28px;
    margin-bottom: -3rem;
    margin-top: 4rem;
  }

  .section {
    height: 100%;
  }
}

@media (min-width: 2101px) {
  .topSectionTitleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .topSectionTitle {
    font-size: 35px;
    font-size: 3.25rem;
    font-weight: 600;
    margin-top: 15%;
  }

  .lowerSectionTitle {
    font-size: 45px;
  }

  .sectionText {
    font-size: 26px;
    width: 60%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .sectionTextSpecial {
    font-size: 26px;
    width: 60%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .rvTentTitle {
    font-size: 45px;
    margin-bottom: -3rem;
    margin-top: 4rem;
  }

  .sevenFortyTwoTitle {
    font-size: 45px;
    margin-bottom: -5rem;
    margin-top: 6rem;
  }

  .section {
    height: 100%;
  }
}
