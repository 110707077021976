.subTitle {
  font-size: 18px;
  font-family: "LibreBaskerville";
  color: grey;
  text-align: center;
  line-break: normal;
  width: auto;
  letter-spacing: 3px;
}

.boxImage {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.imageMap {
  width: 40%;
  object-position: center;
  object-fit: cover;
  height: 100%;
  margin-bottom: 20px;
}

.textTemp {
  font-size: 18px;
  font-family: "LibreBaskerville";
  color: grey;
  letter-spacing: 3px;
  text-align: center;
}

.titleTemp {
  font-family: "AmalfiCoast";
  font-size: 40px;
  color: grey;
  letter-spacing: 3px;
}

@media (min-width: 300px) and (max-width: 900px) {
  .imageMap {
    width: 100% !important;
  }

  .subTitle {
    font-size: 14px;
  }
}
