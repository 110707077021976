@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-top-color: #555;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}
