@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
html,
body {
  color: rgb(var(--foreground-rgb), 0, 0, 0);
  background: rgb(255, 255, 255);
  overflow-x: hidden; /* Hides horizontal scrollbar */
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
  margin: 0;
  padding: 0;
}

/* styles/fonts.css */

@font-face {
  font-family: "librebaskerville";
  src: url("/public/fonts/LibreBaskerville.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AmalfiCoast";
  src: url("/public/fonts/AmalfiCoast.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
