.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Black background with 80% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.pictureBookContainer {
  display: flex;
  justify-content: center;
}

.pictureBook {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  margin-bottom: 100px;
  width: 1000px;
}

.pictureContainer {
  display: flex;
  justify-content: center;
}

.pictureContainer:hover {
  cursor: pointer;
}

.pictureImage {
  width: 320px;
  height: 420px;
  object-fit: cover;
}

@media (min-width: 300px) and (max-width: 900px) {
  .pictureBook {
    grid-template-columns: auto;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .pictureBook {
    grid-template-columns: auto auto;
  }
}
