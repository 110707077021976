.imagesSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.image {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 600px;
  object-fit: "cover";
  object-position: 50% 50%;
}

.imageRow {
  display: none;
}

@media (min-width: 901px) {
  .imageRow {
    display: block;
  }
  .imageColumn {
    display: none;
  }
}

@media (min-width: 750px) and (max-width: 900px) {
  .imagesSection {
    flex-direction: row;
  }
  .image {
    height: 100%;
  }
}
