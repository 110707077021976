.poochBeachContainer {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
}

.textSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rightTitle {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  line-height: 2.5rem;
  letter-spacing: 5px;
  padding-top: 5vh;
  padding-bottom: 3vh;
}

.titleTemp {
  font-family: "AmalfiCoast";
  color: grey;
  text-align: center;
  line-height: 2;
  letter-spacing: 5px;
  padding-top: 5vh;
  padding-bottom: 3vh;
}

.rightText {
  display: flex;
  flex-direction: column;
  font-family: "librebaskerville";
  color: grey;
  line-height: 3rem;
  letter-spacing: 3px;
  text-align: center;
  align-items: center;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  height: 600px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 300px) and (max-width: 399px) {
  .poochBeachContainer {
    flex-direction: column-reverse;
  }

  .rightTitle {
    font-size: 16px;
  }

  .rightText {
    font-size: 14px;
    width: 80%;
    padding-bottom: 15%;
    line-height: 3rem;
    letter-spacing: 3px;
  }

  .image {
    height: 250px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .poochBeachContainer {
    flex-direction: column-reverse;
  }

  .rightTitle {
    font-size: 16px;
  }

  .rightText {
    font-size: 14px;
    width: 75%;
    padding-bottom: 13%;
  }
  .image {
    height: 250px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .poochBeachContainer {
    flex-direction: column-reverse;
  }

  .rightTitle {
    font-size: 16px;
  }
  .image {
    height: 250px;
  }

  .rightText {
    font-size: 16px;
    width: 80%;
    padding-bottom: 13%;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .poochBeachContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 25px;
  }

  .rightText {
    font-size: 16px;
    line-height: 3rem;
    letter-spacing: 3px;
    width: 80%;
    margin-bottom: 10%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .poochBeachContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 30px;
    font-weight: 600;
    margin-top: 7%;
  }

  .rightText {
    font-size: 18px;
    line-height: 3rem;
    letter-spacing: 3px;
    width: 60%;
    padding-bottom: 10%;
  }
}

@media (min-width: 1501px) and (max-width: 1800px) {
  .poochBeachContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 1.5rem;
    font-weight: 600;
    /* margin-top: 7%; */
  }

  .titleTemp {
    font-size: 2.25rem;
    font-weight: 600;
  }

  .rightText {
    font-size: 18px;
    line-height: 3rem;
    letter-spacing: 3px;
    width: 60%;
    /* padding-bottom: 10%; */
  }
}

@media (min-width: 1801px) and (max-width: 2100px) {
  .poochBeachContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 30px;
  }

  .rightText {
    font-size: 18px;
    width: 60%;
    padding-bottom: 10%;
  }
}

@media (min-width: 2101px) {
  .poochBeachContainer {
    flex-direction: row;
  }

  .rightTitle {
    font-size: 35px;
  }

  .rightText {
    font-size: 20px;
    width: 60%;
    padding-bottom: 10%;
  }
}
