.layoutSubPages {
  padding-top: 10%;
  width: 100vw;
}

.layoutSubPagesSub {
  padding-top: 10%;
  width: 100vw;
}

.boxTitle {
  width: 100%;
  scroll-margin-top: 120px;
}

.boxTitleForH {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  min-height: 300px;
}

.titleExperience {
  text-align: center;
  font-family: "AmalfiCoast";
  white-space: nowrap;
  text-overflow: ellipsis;
  color: gray;
  font-size: 2.25rem;
}

.imageExperience {
  width: 100%;
  height: 800px;
  object-fit: cover;
  object-position: 50% 30%;
}

.imageSide {
  width: 50%;
  height: 100%;
  object-fit: cover;
}
.sectionText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "librebaskerville";
  color: grey;
  cursor: pointer;
  text-align: center;
  line-break: normal;
  width: auto;
  margin: 2% 15% 2% 15%;
  line-height: 3rem;
  letter-spacing: 3px;
}

.boxImages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 1200px;
  gap: 0;
  height: 1200px;
}

.imageTree {
  height: 700px;
  width: 100%;
  object-fit: cover;
  /* object-position: 50% 82%; */
}

@media (min-width: 300px) and (max-width: 599px) {
  .imageTree {
    height: 250px;
  }

  .imageExperience {
    height: 250px;
  }

  /* .titleExperience {
    margin-top: 30px;
  } */

  .boxTitle {
    scroll-margin-top: 80px;
  }
}

@media (min-width: 300px) and (max-width: 900px) {
  .boxImages {
    flex-direction: column;
    height: auto;
    width: auto;
  }

  .layoutSubPages {
    padding-top: 20%;
    width: 100vw;
  }

  .layoutSubPagesSub {
    padding-top: 30%;
    width: 100vw;
  }

  .titleExperience {
    font-size: 16px;
  }

  .sectionText {
    font-size: 12px;
  }
}
